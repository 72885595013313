export function generateGenericAvatar(firstName: string = '', lastName: string = '', size: number = 128): string {
  // Get initials
  const initials = firstName.charAt(0) + lastName.charAt(0);

  // Generate hash from first and last name
  const hash = (firstName + lastName).split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

  // Create canvas element
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext('2d');

  // Create gradient
  const gradient = context.createLinearGradient(0, canvas.height, 0, 0);
  const color1 = `hsl(${hash % 360}, 50%, 46%)`;
  const color2 = `hsl(${hash % 360}, 50%, 54%)`;
  gradient.addColorStop(0, color1);
  gradient.addColorStop(1, color2);
  context.fillStyle = gradient;
  context.fillRect(0, 0, canvas.width, canvas.height);

  // Choose text color based on contrast with background
  context.fillStyle = getContrastYIQ(color1, color2) === 'black' ? '#000' : '#fff';

  // Set font size
  const FONT_SIZE = size / 2.28;

  // Set font style and text
  context.font = `bold ${FONT_SIZE}px Inter, sans-serif`;
  context.textAlign = 'center';
  const x = canvas.width / 2;
  const y = canvas.height / 2 + FONT_SIZE / 3;
  context.fillText(initials, x, y);

  // Return canvas data as image source
  return canvas.toDataURL();
}

// Returns "black" or "white" based on the contrast with the background color
function getContrastYIQ(c1: string, c2: string): 'black' | 'white' {
  const r = (parseInt(c1.substring(0, 2), 16) + parseInt(c2.substring(0, 2), 16)) / 2;
  const g = (parseInt(c1.substring(2, 2), 16) + parseInt(c2.substring(2, 2), 16)) / 2;
  const b = (parseInt(c1.substring(4, 2), 16) + parseInt(c2.substring(4, 2), 16)) / 2;
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}
